.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
}

.content {
  max-width: 400px;
  width: 100%;
  padding: 20px;
}

.submit-question-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #ff0000;
  border-radius: 8px;
  padding: 8px;
}

.submit-question-input {
  flex: 1;
  margin-right: 8px;
  border: none;
  resize: none;
  border-radius: 8px;
  outline: none;
  font-size:16px;
  font-family: Söhne, ui-sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.submit-submit-button {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.reset-btn-container {
  display: flex;
  margin-top: 12px;
}
.reset-btn {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  font-size:14px;
  color: blue;
  cursor: pointer; 
  margin-right: 5px;
}

.reset-btn:hover,
.reset-btn:hover:focus {
  text-decoration: underline;
  outline: none;
}

.question {
  margin-bottom: 5px;
}